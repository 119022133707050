<template>
  <div>
    <h1>Grader</h1>
    <AddRankToMember />
  </div>
</template>

<script>
  import AddRankToMember from '@/components/AddRankToMember.vue'

  export default {
    components: { AddRankToMember }
  }
</script>
